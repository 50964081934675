import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Education = () => {
    return (
        <Layout>
            <Seo
                title="Education - BIKESAFE"
                description="It wasn’t long ago that students cycling and walking to school was the norm.  Today, students usually take the bus or are driven to school. Some schools, colleges and universities have even banned bikes all together. That’s scary to think about.  How are students supposed to get their much needed physical activity?"
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Education
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Biking is Good</h3>
                        <p className="flow-text">
                            It wasn’t long ago that students cycling and walking to school was the norm.  Today, students usually take the bus or are driven to school. Some schools, colleges and universities have even banned bikes all together. That’s scary to think about.  How are students supposed to get their much needed physical activity?
                        </p>
                        <h3>Bike Safe Parking Guide</h3>
                        <p className="flow-text">
                            Sometimes planning for bike parking is simple: buy a bike rack. Sometimes, it requires more planning. Either way, you want to get the most for your money while staying within your budget. Bike Safe works with schools, colleges and universities across the country to answer bike parking questions. At Bike Safe, we know the value of quality bike parking, but we also realize that the correct advice is often just as important.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/new-york.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                        <p>
                            <em className="flow-text">"I wanted to express my thanks to all the staff at Bike Safe for the very competitive and professional service they deliver.  They were able to demonstrate flexibility and consideration to meet my school's design requirements."
                                <strong> Paul Jordan, Headteacher, Thames View Infant School, Dagenham</strong>.</em></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Identify Needs and Problem Areas</h3>
                        <p className="flow-text">
                            Taking stock of current bike usage is an important first step in determining your needs. A simple way to start is to conduct a bike count over three typical days during good weather. As you are counting bikes take note of these issues:
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Are bikes parked so they are easily accessible?</li>
                            <li className="border p-2 shade-on-hover">Are bikes parked where they are supposed to be parked and not locked to trees, posts, benches, etc.?</li>
                        </ul>
                    </Col>
                    <Col sm="12" className="text-center">
                        <h3>Choose the Right Locations</h3>
                        <p className="flow-text">
                            Choose the Right Locations
                            Bike racks are often relegated to the back of a building or tucked to the side, out of sight. This discourages the use of the rack as it is not convenient for the cyclist. Racks placed out of the view of passers-by allow thieves more time and privacy to steal. Place bike racks by a main entrance where it is not only convenient for bicyclists, but in a visible area. Not only does the higher visibility cut down on theft, but also raises the profile of your bike program.
                        </p>
                        <h3>Consider Unique Solutions</h3>
                        <p className="flow-text">
                            A major reason that students do not cycle is the inadequate protection provided for their bikes. Bike shelters and compounds offer outdoor bicycles a higher level of protection from the elements than racks left uncovered. A shelter’s roof keeps rain and snow off of bikes and their riders, and a compound can provide added security.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/verticals.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Security Concerns</h3>
                        <p className="flow-text">
                            Different bike racks and installation methods provide varying levels of security. When choosing a rack for your school, college or university you will probably want to consider these questions:
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">How important is security? Is theft an issue at your school?</li>
                            <li className="border p-2 shade-on-hover">How many bikes will you need to park and what space is available?</li>
                            <li className="border p-2 shade-on-hover">What will the rack be placed upon? Asphalt, grass, pavers, concrete?</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Education;